import moment from "moment";
import Api from "./Api";

// 本日を作成.
// ローカル現在時刻の取得
const nowLocal = new Date(); // Wed Apr 22 2020 22:39:03 GMT+0900 (Japan Standard Time)
// UTCとローカルタイムゾーンとの差を取得し、分からミリ秒に変換
const diff = nowLocal.getTimezoneOffset() * 60 * 1000; // -540 * 60 * 1000 = -32400000
// toISOString()で、UTC時間になってしまう（-9時間）ので、日本時間に9時間足しておく
const plusLocal = new Date(nowLocal - diff); // Thu Apr 23 2020 07:39:03 GMT+0900 (Japan Standard Time)
// ISO形式に変換（UTCタイムゾーンで日本時間、というよくない状態）
let iso = plusLocal.toISOString(); // "2020-04-22T22:39:03.397Z"
// UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
iso = iso.slice(0, 19) + "+09:00"; // "2020-04-22T22:39:03+09:00"

iso = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ss"); // added by Elavarasan

export default {
  /**
   * message Insert
   * @param {*} form
   * @returns object
   */
  register(form) {
    return Api().post("api/v1/messages", form);
  },

  register_meta(form, id) {
    return Api().post("api/v1/messages/" + `${id}` + "/metas", form);
  },

  register_file(form, id) {
    return Api().post("api/v1/files/messages/" + `${id}`, form);
  },

  /**
   * All message list
   * @returns object
   */
  list(type) {
    return Api().get(
      "api/v1/messages?type=" +
        `${type}` +
        "&desc=published_at&status=公開&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso
    );
  },

  /**
   * All message list
   * @returns object
   */
  getcompany(type) {
    return Api().get("api/v1/messages?type=" + `${type}`);
  },
  listQuery(query) {
    return Api().get("api/v1/messages?" + `${query}`);
  },

  listNum(type, num) {
    return Api().get(
      "api/v1/messages?type=" +
        `${type}` +
        "&status=公開,承認済&desc=published_at&per=" +
        `${num}` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso
    );
  },
  listOffset(type, num) {
    return Api().get(
      "api/v1/messages?type=" +
        `${type}` +
        "&status=公開,承認済&desc=published_at&offset=" +
        `${num - 1} ` +
        "&limit=" +
        `18` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso
    );
  },

  listNumMessage(type, num, user, group, start, end, tags = null) {
    var url = "api/v1/messages?where=type@" + `${type}`;
    if (user != null) {
      url += "AND from_id@" + `${user}`;
    }
    if (group != null) {
      url += "&groups=" + `${group}`;
    }
    if (tags != null) {
      url += "&tags=" + `${tags}`;
    }
    if (type == "sdgs" || type == "challenge") {
      url +=
        "&status=承認済&desc=created_at&per=" +
        `${num}` +
        "&datetime=created_at@between,2020-01-01T00:00:00/" +
        iso;
    } else if (
      type == "news" ||
      type == "column" ||
      type == "photolog" ||
      type == "idea"
    ) {
      url +=
        "&status=公開,承認済&desc=published_at&per=" +
        `${num}` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso;
    } else {
      url +=
        "&status=公開&desc=published_at&per=" +
        `${num}` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso;
    }
    if (start != null && end != null) {
      url += "&datetime=published_at@between," + `${start}` + "/" + `${end}`;
    }
    return Api().get(url);
  },

  listNumAdmin(type, num, order, status, pagination = null) {
    if (status != "all") {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          "AND status@" +
          `${status}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}` +
          "&year=" +
          `${pagination.searchYear}` +
          "&month=" +
          `${pagination.searchMonth}`
      );
    } else {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}` +
          "&year=" +
          `${pagination.searchYear}` +
          "&month=" +
          `${pagination.searchMonth}`
      );
    }
  },

  listNumUser(type, num, user, order, status) {
    if (status != "all") {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          "AND from_id@" +
          `${user}` +
          "AND status@" +
          `${status}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}`
      );
    } else {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          "AND from_id@" +
          `${user}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}`
      );
    }
  },
  listNumTarget(type, num, user, order, status) {
    if (status != "all") {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          "&target_ids=" +
          `${user}` +
          "& status=" +
          `${status}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}`
      );
    } else {
      return Api().get(
        "api/v1/messages?where=type@" +
          `${type}` +
          " AND status@申請中,承認済,却下&target_ids=" +
          `${user}` +
          "&" +
          order +
          "=published_at&per=" +
          `${num}`
      );
    }
  },
  listMultipleNumTarget(field, value, user, num) {
    return Api().get(
      "api/v1/messages?where=" +
        `${field}` +
        "@" +
        `${value}` +
        "&target_ids=" +
        `${user}` +
        "&desc=published_at&status=公開&per=" +
        `${num}`
    );
  },

  listNumCount(type, user, start) {
    var url =
      "api/v1/messages?where=type@" +
      `${type}` +
      "&target_ids=" +
      `${user}` +
      "&status=公開&=count";
    if (start != null) {
      url += "&datetime=published_at@between," + `${start}` + "/" + iso;
    } else {
      url += "&datetime=published_at@between,2020-01-01T00:00:00/" + iso;
    }
    return Api().get(url);
  },

  listNumCountMonth(type, user, start, end) {
    var url = "api/v1/messages?where=type@" + `${type}`;
    if (user != null) {
      url += "AND from_id@" + `${user}`;
    }
    if (type == "sdgs" || type == "challenge") {
      url += "&=count&status=承認済";
    } else {
      url += "&=count&status=公開";
    }
    if (start != null && end != null) {
      url += "&datetime=published_at@between," + `${start}` + "/" + `${end}`;
    }
    return Api().get(url);
  },

  listNumCountApproval(user) {
    return Api().get(
      "api/v1/messages?where=type@challenge,idea,sdgs,column,news,photolog AND status@申請中&target_ids=" +
        `${user}`
    );
  },

  listChild(id, type) {
    return Api().get(
      "api/v1/messages/" +
        `${id}` +
        "/childs?type=" +
        `${type}` +
        "&asc=published_at"
    );
  },

  /**
   * All message list multiple
   * @returns object
   */
  listMultiple(field, value, desc, num, nottags = null) {
    var url =
      "api/v1/messages?where=" +
      `${field}` +
      "@" +
      `${value}` +
      "AND status@公開,承認済&desc=" +
      `${desc}` +
      "&per=" +
      `${num}` +
      "&datetime=published_at@between,2020-01-01T00:00:00/" +
      iso;

    if (nottags != null) {
      url += "&nottags=" + `${nottags}`;
    }
    return Api().get(url);
  },

  /**
   * Show the currect message
   * @returns object
   */
  show(id) {
    return Api().get("api/v1/messages/" + `${id}`);
  },

  showmetakey(id, key, value) {
    return Api().get(
      "api/v1/messages/" +
        `${id}` +
        "/metas?where=" +
        `${key}` +
        "@" +
        `${value}`
    );
  },
  getFile(id) {
    return Api().get("api/v1/files/messages/" + `${id}`);
  },
  showfile(id, key, value) {
    return Api().get(
      "api/v1/files/messages/" +
        `${id}` +
        "?where=" +
        `${key}` +
        "@" +
        `${value}`
    );
  },

  /**
   * Edit the currect message
   * @returns object
   */
  edit(id) {
    return Api().get("api/v1/messages/" + `${id}`);
  },

  /**
   * message Update
   * @param {*} form ,id
   * @returns object
   */
  update(form, id) {
    return Api().put("api/v1/messages/" + `${id}`, form);
  },
  update_meta(form, message_id, meta_id) {
    return Api().put(
      "api/v1/messages/" + `${message_id}` + "/metas/" + `${meta_id}`,
      form
    );
  },

  update_file(form, message_id, file_id) {
    return Api().put(
      "api/v1/files/messages/" + `${message_id}` + "/" + `${file_id}`,
      form
    );
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  delete(id) {
    return Api().delete("api/v1/messages/" + `${id}`);
  },

  file_delete(id, file_id) {
    return Api().delete(
      "api/v1/files/messages/" + `${id}` + "/" + `${file_id}`
    );
  },
  delete_meta(message_id, meta_id) {
    return Api().delete(
      "api/v1/messages/" + `${message_id}` + "/metas/" + `${meta_id}`
    );
  },

  getMeta(message_id) {
    return Api().get("api/v1/messages/" + `${message_id}` + "/metas");
  },

  // タグ一覧取得
  tag_list(tag_array) {
    return Api().get("api/v1/tags?where=name@" + `${tag_array}`);
  },

  //あらかじめユーザーを取得したapi
  listNumMessage02(type, num, user, group, start, end) {
    var url = "api/v1/messages_adduser?where=type@" + `${type}`;
    if (user != null) {
      url += "AND from_id@" + `${user}`;
    }
    if (group != null) {
      url += "&groups=" + `${group}`;
    }
    url +=
      "&status=公開,承認済&desc=published_at&per=" +
      `${num}` +
      "&datetime=published_at@between,2020-01-01T00:00:00/" +
      iso;
    if (start != null && end != null) {
      url += "&datetime=published_at@between," + `${start}` + "/" + `${end}`;
    }
    return Api().get(url);
  },

  //トピックス専用api
  topic_index() {
    var url = "api/v1/topic_index";
    return Api().get(url);
  },

  //notificationsのidの取得
  notifications_id(messages_id) {
    return Api().get("api/v1/auth/notifications/messages/" + `${messages_id}`);
  },

  //未読の取得
  notifications_read(notification_id) {
    return Api().get("api/v1/auth/notifications/" + `${notification_id}`);
  },

  //pilicy show
  policy_show(policy_group) {
    return Api().get("api/v1/messages/policy/" + `${policy_group}`);
  },
  //policy_file
  register_policy_file(form, id) {
    return Api().post("api/v1/files/messagemeta/" + `${id}`, form);
  },
  //policy_file
  show_policy_file(id) {
    return Api().get("api/v1/files/messagemeta/" + `${id}`);
  },
  update_policy_file(form, id, file_id) {
    return Api().put(
      "api/v1/files/messagemeta/" + `${id}` + "/" + `${file_id}`,
      form
    );
  },
  policy_file_delete(id, file_id) {
    return Api().delete(
      "api/v1/files/messagemeta/" + `${id}` + "/" + `${file_id}`
    );
  },

  approvelSdgsAndChallenge(type, num, user, lastYear) {
    var url =
      "api/v1/messages?where=type@" +
      `${type}` +
      "AND from_id@" +
      `${user}` +
      "&status=承認済&desc=created_at&per=" +
      `${num}` +
      "&datetime=created_at@between," +
      `${lastYear}` +
      "-10-01T00:00:00/" +
      iso;

    return Api().get(url);
  },

  getChallangeSdgsByUserId(user, type, lastYear) {
    return Api().get(
      "api/v1/messages?where=type@" +
        `${type}` +
        " AND status@承認済,公開" +
        "&from_id=" +
        `${user}` +
        "&desc" +
        "=published_at" +
        "&datetime=created_at@between," +
        `${lastYear}` +
        "-10-01T00:00:00/" +
        iso
    );
  },

  listNumMessagePrd(type, num, user, group, start, end, tags = null) {
    var url = "api/v1/messages?where=type@" + `${type}`;

    if (user != null) {
      url += "AND from_id@" + `${user}`;
    }

    if (tags != null) {
      url += "&tags=" + `${tags}`;
    }
    if (type == "sdgs" || type == "challenge") {
      url +=
        "&status=承認済&desc=created_at&per=" +
        `${num}` +
        "&datetime=created_at@between,2020-01-01T00:00:00/" +
        iso;
    } else if (
      type == "news" ||
      type == "column" ||
      type == "photolog" ||
      type == "idea"
    ) {
      url +=
        "&status=公開,承認済&desc=published_at&per=" +
        `${num}` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso;
    } else {
      url +=
        "&status=公開&desc=published_at&per=" +
        `${num}` +
        "&datetime=published_at@between,2020-01-01T00:00:00/" +
        iso;
    }
    if (start != null && end != null) {
      url += "&datetime=published_at@between," + `${start}` + "/" + `${end}`;
    }
    return Api().get(url);
  },


  DailyList(group, tags = null) {
    var url = "api/v2/messages_daily?per=25";
    if (group != null) {
      url += "&group=" + `${group}`;
    }
    if (tags != null) {
      url += "&tag=" + `${tags}`;
    }
    return Api().get(url);
  },
  messagesList(type, users=null, page = 25, order=null, status = null, pagenumber = null) {
    var url = `api/v2/messages_index?per=${page}`;
    if (type != null) {
      url += "&type=" + `${type}`;
    }
    if (users != null) {
      url += "&users=" + `${users}`;
    }
    if (order != null) {
      url += "&order=" + `${order}`;
    }
    if (status != null) {
      url += "&status=" + `${status}`;
    }
    if (pagenumber != null) {
      url += "&page=" + `${pagenumber}`;
    }
    return Api().get(url);
  },
  getEmployeeMessageList(type, users=null, page = 25, order=null, status = null, pagenumber = null) {
    var url = `api/v2/messages_employee?per=${page}`;
    if (type != null) {
      url += "&type=" + `${type}`;
    }
    if (users != null) {
      url += "&users=" + `${users}`;
    }
    if (order != null) {
      url += "&order=" + `${order}`;
    }
    if (status != null) {
      url += "&status=" + `${status}`;
    }
    if (pagenumber != null) {
      url += "&page=" + `${pagenumber}`;
    }
    return Api().get(url);
  },
  approval_list(type, page, user, order, status,pagenumber=null) {
    var url = `api/v2/approval_list?per=${page}`;

    if (type != null) {
      url += "&type=" + `${type}`;
    }
    if (user != null) {
      url += "&user=" + `${user}`;
    }
    if (order != null) {
      url += "&order=" + `${order}`;
    }
    if (status != null) {
      url += "&status=" + `${status}`;
    }
    if (pagenumber != null) {
      url += "&page=" + `${pagenumber}`;
    }
    return Api().get(url);
  },
  approval_received(type, page, user, order, status) {
    var url = `api/v2/approval_received?per=${page}`;

    if (type != null) {
      url += "&type=" + `${type}`;
    }
    if (user != null) {
      url += "&user=" + `${user}`;
    }
    if (order != null) {
      url += "&order=" + `${order}`;
    }
    if (status != null) {
      url += "&status=" + `${status}`;
    }
    return Api().get(url);
  },

  approval_send(types, page, users=null, pagenumber=null) {
    var url = `api/v2/approval_send?per=${page}`;
    if (types != null) {
      url += "&types=" + `${types}`;
    }
    if (users != null) {
      url += "&users=" + `${users}`;
    } 
    if (pagenumber != null) {
      url += "&page=" + `${pagenumber}`;
    }
    return Api().get(url);
  },

  myPostMessages(types, page, users=null) {
    var url = `api/v2/my_post_messages?per=${page}`;
    if (types != null) {
      url += "&types=" + `${types}`;
    }
    if (users != null) {
      url += "&users=" + `${users}`;
    }
    return Api().get(url);
  },
  userIntroduction(user) {
    var url = `api/v2/user_introduction?`;
    if (user != null) {
      url += "user=" + `${user}`;
    }
    return Api().get(url);
  },
  // messagesList(type, users=null, page = 25, order=null, status = null, pagenumber = null)
  myTargetMessages(types, user, page , pagenumber = null) {
    var url = `api/v2/my_target_messages?per=${page}`;
    if (types != null) {
      url += "&types=" + `${types}`;
    }
    if (user != null) {
      url += "&user=" + `${user}`;
    }
    if (pagenumber != null) {
      url += "&page=" + `${pagenumber}`;
    }
    return Api().get(url);
  },
  myPostCount(user, start, end) {
    var url = `api/v2/post_count?user=${user}&start=${start}&end=${end}`;
    return Api().get(url);
  },
  targetMessageCount(user, start) {
    var url = `api/v2/target_count?user=${user}&start=${start}`;
    return Api().get(url);
  },
  approvalMail(obj, id){
    return Api().post(`api/v2/approval_mail/${id}`, obj);
  },
  registerPhotolog(form) {
    return Api().post("api/v2/photologs", form);
  },
  async messageReport(pagination) {
    return Api().get(
      "api/v1/messages-report-export?type=" +
      `${pagination.messageType}`,
      {
        responseType: "arraybuffer",
      }
    );
  },
  async messageCount(msgId) {
    var url = `api/v2/user/sdgsreportcount/${msgId}`;
    return Api().get(url);
  },
};
